import * as React from 'react';
import cn from 'classnames';
import s from './Hero.module.css';

type Props = {
  title: React.ReactNode;
  description: string;
  className?: string;
};

export const Hero: React.FC<Props> = ({ className, title, description, children }) => (
  <div className={cn('flex flex-col tr-bg-gray-2', s.root, className)}>
    <div className="container flex flex-col items-center text-center">
      <div className="text-5xl font-semibold leading-none mb-6">{title}</div>
      <div className={cn('text-xl mb-12', s.description)}>{description}</div>
      {children}
    </div>
  </div>
);

Hero.defaultProps = {
  className: undefined,
};
