/* eslint-disable max-len */
import * as React from 'react';
// import desktopImg from 'images/desktopSustainableRelationships.jpg';
// import retinaDesktopImg from 'images/desktopSustainableRelationships@2x.jpg';
// import mobileImg from 'images/mobileSustainableRelationships.jpg';
// import retinaMobileImg from 'images/mobileSustainableRelationships@2x.jpg';
import s from './SustainableRelationships.module.css';

export const SustainableRelationships = () => (
  <div className="container py-20 mxl:py-16 mlg:py-12 mmd:py-10 msm:py-8">
    <div className={s.title}>Устойчивые отношения во всем мире</div>
    <div className={s.description}>
      Мы в основном источник в развивающихся странах. Мы покупаем по справедливой цене, предпочтительно напрямую от
      производителей или кооперативов. Таким образом, производители не проигрывают посредникам. Мы также помогаем
      производителям расширять свои знания, увеличивать урожайность и увеличивать доход. Так мы помогаем всему
      сообществу достичь экономической независимости.
    </div>
    {/* <picture>
      <source media="(max-width: 600px)" srcSet={`${mobileImg}, ${retinaMobileImg} 2x`} />
      <source media="(min-width: 600px)" srcSet={`${desktopImg}, ${retinaDesktopImg} 2x`} />
      <img src={desktopImg} srcSet={`${retinaDesktopImg} 2x`} alt="" />
    </picture> */}
  </div>
);
