/* eslint-disable max-len */
import * as React from 'react';
import { Icon } from './icons';
import topLine from './advantagesTop.svg';
import bottomLine from './advantagesBottom.svg';
import s from './Advantages.module.css';

const data = [
  {
    title: 'Доставка 24 часа',
    description: 'Прямые поставки от наших собственных производителей',
  },
  {
    title: 'Гарантия качества',
    description: 'Собственное производство для максимального контроля',
  },
  {
    title: 'Отборный продукт',
    description: 'Единственный в Европе специалист по манго и авокадо',
  },
  {
    title: 'Прямые поставки',
    description: 'Прямые поставки от наших собственных производителей',
  },
  {
    title: 'Круглосуточная поддержка',
    description: 'Прямые поставки от наших собственных производителей',
  },
  {
    title: 'Налаженная логистика',
    description: 'Прямые поставки от наших собственных производителей',
  },
];

export const Advantages = () => (
  <>
    <img src={topLine} alt="" className="w-full" />
    <div className="tr-bg-gray-2 py-16 mlg:py-12 mmd:py-10 msm:py-8">
      <div className="container">
        <div className={s.title}>Вы получите выгодные условия</div>
        <div className="grid grid-cols-3 gap-8 mb-12 mlg:grid-cols-2 mlg:gap-6 mlg:mb-8 msm:grid-cols-1 msm:gap-2 msm:mb-6">
          {data.map((a, i) => (
            <div key={a.title} className={s.item}>
              <div className="flex items-center mb-4 mlg:mb-2">
                <div
                  className="flex items-center justify-center w-12 h-12 rounded-lg border mr-6 mlg:w-8 mlg:h-8 mlg:mr-4 msm:mr-3"
                  style={{ borderColor: 'rgba(61, 92, 0, 0.16)' }}
                >
                  <Icon name={`advantage${i + 1}`} className="w-8 h-8 mlg:w-5 mlg:h-5" />
                </div>
                <div className="text-lg font-semibold leading-6 mlg:text-base mlg:leading-5 msm:text-sm">{a.title}</div>
              </div>
              <div className="leading-5 mlg:text-sm mlg:leading-4 msm:text-xs">{a.description}</div>
            </div>
          ))}
        </div>
        <div className={s.description}>
          Мы верим в справедливую торговлю. Мы также стремимся к более устойчивому производству и переработке. Вот
          почему мы являемся лидерами во всей цепочке поставок, потому что прибыль должна идти рука об руку с устойчивой
          торговлей. Мы хотим вдохновить другие компании в сфере фруктов.
        </div>
      </div>
    </div>
    <img src={bottomLine} alt="" className="w-full" />
  </>
);
