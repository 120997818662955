import * as React from 'react';
import { Link } from 'gatsby';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Hero } from 'components/Hero';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ThaiExotic } from 'components/ThaiExotic';
import { LargestImporter } from 'components/LargestImporter';
import { Advantages } from 'components/Advantages';
import { SustainableRelationships } from 'components/SustainableRelationships';
import { CarryProducts } from 'components/CarryProducts';
import { ContactUs } from 'components/ContactUs';
import s from './about.module.css';

const AboutPage = () => (
  <Layout>
    <SEO title="О компании" />
    <Hero
      title="О компании TreeRipe"
      description="История наших фруктов начинается на плантациях наших производителей. Там, под тропическим или субтропическим летним солнцем, они получают все время, питание и личное внимание, которое им нужно, чтобы расти от расцвета до совершенства."
    >
      <Link to="/contacts" className="button button-normal button-yellow">
        Связаться с нами
      </Link>
    </Hero>
    <Breadcrumbs label="О компании" />
    <ThaiExotic />
    <LargestImporter className={s.largestImporter} />
    <Advantages />
    <SustainableRelationships />
    <CarryProducts />
    <ContactUs />
  </Layout>
);

export default AboutPage;
