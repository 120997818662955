/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { countriesData, CountryCode } from 'data/countries';
import { Icon } from './icons';
import s from './CarryProducts.module.css';

export const CarryProducts = () => (
  <div className="container pt-20 mxl:pt-16 mlg:pt-12 mmd:pt-10 msm:pt-8">
    <div className={s.title}>Возим продукты из 5+ стран мира</div>
    <div className={s.description}>
      Мы верим в справедливую торговлю. Мы также стремимся к более устойчивому производству и переработке. Вот почему мы
      являемся лидерами во всей цепочке поставок, потому что прибыль должна идти рука об руку с устойчивой торговлей. Мы
      хотим вдохновить другие компании в сфере фруктов.
    </div>
    <div className="grid grid-cols-4 gap-8 mb-12 mxl:grid-cols-3 mlg:mb-8 mmd:grid-cols-2 mmd:gap-6 msm:grid-cols-1 msm:gap-2 msm:mb-6">
      {Object.keys(countriesData).map((k: CountryCode) => {
        const c = countriesData[k];

        return (
          <div key={c.name} className={s.item}>
            <Icon name={c.iconName} className="w-12 h-12 mr-4 mmd:w-10 mmd:h-10" />
            <div className="text-xl font-semibold leading-6 mlg:text-lg mmd:text-base">{c.name}</div>
          </div>
        );
      })}
    </div>
    <Link to="/delivery" className={cn('button button-normal button-ghost', s.button)}>
      Подробнее о доставке
    </Link>
  </div>
);
