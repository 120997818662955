/* eslint-disable max-len */
import * as React from 'react';
import cn from 'classnames';
import img from 'images/thaiExotic.jpg';
import imgRetina from 'images/thaiExotic@2x.jpg';
import s from './ThaiExotic.module.css';

export const ThaiExotic = () => (
  <section className="container flex items-center justify-between py-16">
    <div className={s.left}>
      <div className="text-5xl font-semibold leading-none mb-6">Специалист по тайской экзотике – прямые поставки</div>
      <div className={cn('text-xl', s.description)}>
        Круглогодичная доставка. Каждый этап созревания. Всегда Class I . Для предприятий розничной торговли,
        общественного питания и оптовиков по всей Европе.
      </div>
    </div>
    <img src={img} srcSet={`${imgRetina} 2x`} alt="" />
  </section>
);
