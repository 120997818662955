/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import logo from 'images/logo.png';
import logoRetina from 'images/logo@2x.png';
import img from 'images/largestImporter.jpg';
import imgRetina from 'images/largestImporter@2x.jpg';
import s from './LargestImporter.module.css';

type Props = {
  className?: string;
};

export const LargestImporter = ({ className }: Props) => (
  <section className={cn('relative tr-bg-gray-2', s.root, className)}>
    <div className="container flex items-center justify-between">
      <div className={s.left}>
        <img src={logo} srcSet={`${logoRetina} 2x`} alt="" className="mb-12" />
        <div className="text-5xl font-semibold leading-none mb-6">Крупнейший импортер</div>
        <div className={cn('text-xl mb-12', s.description)}>
          Мы верим в справедливую торговлю. Мы также стремимся к более устойчивому производству и переработке. Вот
          почему мы являемся лидерами во всей цепочке поставок, потому что прибыль должна идти рука об руку с устойчивой
          торговлей. Мы хотим вдохновить другие компании в сфере фруктов.
        </div>
        <Link to="/contacts" className="button button-normal button-green">
          Связаться с нами
        </Link>
      </div>
      <img src={img} srcSet={`${imgRetina} 2x`} alt="Крупнейший импортер" />
    </div>
  </section>
);

LargestImporter.defaultProps = {
  className: undefined,
};
